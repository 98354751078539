import i18next from 'i18next';
import { html } from 'lit-html';
import moment from 'moment';
import analysisImage from '../../../assets/images/analisis.svg';
import {
  getColour,
  getDecimal,
  getDecimalAnalysisSumm,
} from '../../../shared/components/chart/chart-helpers';
import { MAGNITUD_SYMBOLS } from '../../../shared/enum/magnitud-symbols';
import { getMinutesList } from '../../../utils/dateFormat';
import { getState } from '../../../utils/state';
import { getUrlParams } from '../../../utils/url';
import { Utils } from '../../../utils/utils';
import { ANALYSIS_TRUNCS, UNIVERSE_ID } from '../form/analysis-form.service';

const AnalysisSummary = (data, chart, analysis, info, date) => {
  if (analysis.groupby === 'month') {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();

    data.forEach((elem) => {
      // Eliminar los valores con id igual a null
      if (elem.values) {
        elem.values = elem.values.filter((value) => {
          // Convertir el id a cadena y luego comparar
          return value.id === undefined;
        });
      }

      // Si elem.value tiene un id, cambiar el valor completo a undefined
      if (elem.value == [] && elem.value.id !== undefined) {
        elem.value = undefined;
      }
    });
  }
  // ORDER ASC BY NAME
  let baseIndicator = data[0];
  let tabSelected = analysis.tabSelected.label;
  let composited = analysis.indicator.composited;
  let disaggregated = analysis.indicator.disaggregated;
  let listOfIds = data.map((elem) => {
    return elem.id;
  });
  if (tabSelected === 'comparative' && analysis.indicators && composited) {
    analysis.indicators
      .filter((el) => el.id != undefined)
      .map((el) => {
        if (!listOfIds.includes(el.id)) {
          data.push({
            color: getColour(el.id),
            excelId: el.id,
            geos: [],
            id: el.id,
            name: el.name,
            short_name: el.name,
            tiempo: el.tiempo,
            value: { x: data[0]?.value?.x, y: 'UNDEFINED' },
            values: [],
          });
        }
      });
  }

  // THIS IS FOR FILTER GEOIDS FOR COMPOSITED (?)
  // if (getUrlParams().geoids) {
  //   data = data.filter((el) => el.geos[0]?.geo_id == getUrlParams().geoids);
  // }
  let newData;
  let compareUniverse =
    analysis.indicator.universe && analysis.indicator.universe[0].id;
  if (
    getState().analysis.isBreakdown ||
    compareUniverse == UNIVERSE_ID.GEN_AND_CON ||
    analysis.indicator.id == 1739 ||
    analysis.indicator.id == 847
  ) {
    let tempData = [...data][0];
    data.shift();
    newData = [tempData, ...sortAlphaNumeric(data, 'ASC', 'name')];
  } else {
    let dataSameId, dataNotSameId;
    dataSameId = data.filter((el) => el?.excelId == baseIndicator.excelId);
    dataSameId = dataSameId.sort((a, b) => (a.name < b.name ? -1 : 1));
    dataNotSameId = data.filter((el) => el?.excelId != baseIndicator.excelId);
    dataNotSameId = dataNotSameId.sort((a, b) => (a.name < b.name ? -1 : 1));
    newData = [...dataSameId, ...dataNotSameId];
  }
  if (analysis.indicator.id == 1293 && newData.length == 4) {
    let tempNewData = [...newData];
    newData[0] = tempNewData[3];
    newData[1] = tempNewData[0];
    newData[2] = tempNewData[1];
    newData[3] = tempNewData[2];
  } else if (analysis.indicator.id == 1293 && newData.length == 3) {
    let tempNewData = [...newData];
    newData[0] = tempNewData[2];
    newData[1] = tempNewData[0];
    newData[2] = tempNewData[1];
  }

  if (analysis.indicator.id === 1077) {
    let tempNewData = [...newData];
    newData[0] = tempNewData[1];
    newData[1] = tempNewData[0];
  }
  let compareIds = getUrlParams()?.compare_indicators
    ? getUrlParams().compare_indicators.split(',')
    : [];
  newData = newData.filter(
    (elem) => !compareIds.includes(elem?.excelId) && elem?.dots
  );

  return html`
  <div class="esios-analysis-summary">
    <div class="esios-analysis-summary__container">
      ${newData.map(SummaryItem(chart, analysis, info, baseIndicator, date))}
    </div>
  </div>
  </div>
`;
};

const AnalysisCompoSummary = (containerId, data, chart) => {
  let filterData = data.filter((e) => e.value.length > 0);
  let ulLegend = d3
    .select('#' + containerId)
    .append('ul')
    .attr('class', 'legend');

  let keys = ulLegend
    .selectAll('.key')
    .data(filterData)
    .enter()
    .append('li')
    .attr('class', 'esios-listEnergyItems__item-with-value');

  keys
    .html((item) => renderLi(item))
    .on('mouseover', function (d) {
      if (chart.innerArrow) {
        chart.innerArrow.moveTo(d.id);
      }
    });
};

const hoverPie = (pie) => {
  let title = document.getElementById('comp-innerContent-title');
  let value = document.getElementById('comp-innerContent-value');

  pie.on('Pie-piece/mouseover', function (info) {
    title.innerText = info.data.name;
    value.innerHTML = `
    ${info.data.sign}${
      info.value === 0 ? '-' : Utils.toNumber(info.value, 1, 'comma')
    }
      <div class="unit">${MAGNITUD_SYMBOLS[info.data.magnitud[0].id]}</div>
    `;
  });
};

function SummaryItem(chart, analysis, info, baseIndicator, dateTrail) {
  const disableds = [];
  return (data, index) => {
    const id = info.indicator.id;
    const name = data.name;
    const value = data.value || {};
    const itemId = `${data.id}`;
    const currentGeo = data.geos.filter(
      (geoid) => geoid.geo_name === itemId.toString().split('-')[1]
    )[0] || { geo_id: '', geo_name: '' };
    const handleClick = () => {
      const btn = document.getElementById(`anasum-btn-${index}`);
      btn.classList.contains('disabled')
        ? btn.classList.remove('disabled')
        : btn.classList.add('disabled');
      disableds[index] = btn.classList.contains('disabled');
      btn.style.backgroundColor = disableds[index] ? '' : data.color;
      chart.series.toggle(itemId);
    };
    const groupTime = ANALYSIS_TRUNCS[data.tiempo[0].id];
    let tabSelected = analysis.tabSelected.label;

    let baseIndicatorByGroup = (groupBy) => {
      let date = moment(dateTrail).format('DD-MM-YYYYTHH:mm');
      let newDate;

      if (groupBy == 219) {
        let minutes = moment(dateTrail).format('mm');
        minutes = Math.round(minutes / 5) * 5;
        newDate = moment(dateTrail).minutes(minutes).format('DD-MM-YYYYTHH:mm');
      } else if (groupBy == 154) {
        newDate = date.slice(0, -1);
        newDate = newDate + '0';
      } else if (groupBy == 4) {
        newDate = moment(dateTrail).minutes('00').format('DD-MM-YYYYTHH:mm');
      } else if (groupBy == 218) {
        let minutes = moment(dateTrail).format('mm');
        minutes = Math.round(minutes / 15) * 15;
        newDate = moment(dateTrail).minutes(minutes).format('DD-MM-YYYYTHH:mm');
      } else if (groupBy == 2) {
        newDate = moment(date, 'DD-MM-YYYYTHH:mm').startOf('month');
      } else if (groupBy == 3) {
        newDate = moment(date, 'DD-MM-YYYYTHH:mm').startOf('day');
      } else if (groupBy == 1) {
        newDate = moment(date, 'DD-MM-YYYYTHH:mm').startOf('year');
      } else {
        newDate = date;
      }
      return new Date(
        moment(newDate, 'DD-MM-YYYYTHH:mm').format('YYYY-MM-DD HH:mm')
      );
    };
    let valWithData;
    if (!value.y) {
      valWithData =
        tabSelected === 'comparative'
          ? data.values.find((el) => {
              return (
                new Date(baseIndicatorByGroup(data.tiempo[0].id)).getTime() ==
                new Date(moment(el?.x)).getTime()
              );
            })
          : undefined;
    } else {
      valWithData = value;
    }
    // FUNCTION
    // if (valWithData == undefined && data.values.length > 0) {
    //   let dataChangeOrder = data.values;
    //   if (moment(dataChangeOrder[0]) >= moment(dataChangeOrder.at(-1))) {
    //     dataChangeOrder = data.values.reverse();
    //   }
    //   valWithData = dataChangeOrder.find((el) => {
    //     return (
    //       moment(dateTrail).format('YYYY-MM-DD HH:mm') >
    //       moment(el?.x).format('YYYY-MM-DD HH:mm')
    //     );
    //   });
    // }
    let valueToShow;
    let rdn = data?.magnitud
      ? getDecimalAnalysisSumm(data?.magnitud[0]?.id)
      : 1;
    if (value?.y != 'UNDEFINED' && valWithData != undefined) {
      valueToShow = Utils.toNumber(value?.y || valWithData?.y, rdn, 'comma');
    }

    // FUNCTION
    // if (valueToShow === undefined) {
    //   let grpby = getUrlParams().groupby;
    //   let date = moment(dateTrail).format(
    //     grpby == 'month' ? 'MM-YYYY' : 'YYYY'
    //   );
    //   let findval = data.values.filter((el) => {
    //     if (
    //       moment(el?.x).format(grpby == 'month' ? 'MM-YYYY' : 'YYYY') == date
    //     ) {
    //       return el;
    //     }
    //   });

    //   valueToShow = Utils.toNumber(
    //     findval[0]?.y,
    //     rdn,
    //     'comma'
    //   );
    // }
    const getDate = () => {
      let date;

      if (index === 0) {
        date = index = dateTrail || value?.x || valWithData?.x;
      } else {
        if (analysis.tabSelected.label == 'temporal') {
          date =
            value.z ||
            dateTrail ||
            value?.x ||
            valWithData?.x ||
            baseIndicator.value?.x;
        } else {
          date =
            dateTrail ||
            value.z ||
            value?.x ||
            valWithData?.x ||
            baseIndicator.value?.x;
        }
      }

      return moment(date).format(formatDate(analysis.groupby)) || '';
    };
    return html`
      <div class="esios-analysis-summary__summary-item">
        <button
          @click=${handleClick}
          id="anasum-btn-${index}"
          class="esios-analysis-summary__icon-eye ${disableds[index]
            ? 'disabled'
            : ''}"
          style="background-color: ${data.color};"
        ></button>
        <span class="esios-analysis-summary__label">${name}</span>
        <span class="esios-analysis-summary__label-date">(${getDate()})</span>

        <div class="esios-analysis-summary__value">
          ${data.value != null &&
          data.value.length == 0 &&
          data.values != null &&
          data.values.length == 0
            ? html`<p class="no-data-text">
                ${i18next.t('no_data_available')}
              </p>`
            : html` <span class="esios-analysis-summary__caption">
                  ${
                    // Verificamos las condiciones adicionales
                    data.unit === '€/MWh' &&
                    data.id === '10463' &&
                    valueToShow === '0,00'
                      ? '-' // Si se cumplen las condiciones, mostramos un guion
                      : valueToShow || '-'
                  }
                  <!-- Si no, mostramos el valor o un guion si es nulo -->
                </span>
                <span class="esios-analysis-summary__unit">${data.unit}</span>`}
        </div>

        ${!(!data.disaggregated && index == 0)
          ? html`<a
              href="/${i18next.t('routes.lng')}/${i18next.t(
                'routes.analysis'
              )}/${data.excelId}?vis=${analysis.tabSelected.index +
              1}&start_date=${analysis.start_date}&end_date=${analysis.end_date}&geoids=${currentGeo.geo_id}"
              class="icon-widget-analysis"
              target="_blank"
            >
              <img style="vertical-align: middle" src="${analysisImage}" />
            </a>`
          : ''}
      </div>
    `;
  };
}

export function sortAlphaNumeric(data, orderBy, key) {
  const regexNonAlpha = /[^a-zA-Z]/g;
  const regexNumeric = /[+-]?([0-9]*[.])?[0-9]+/g;
  let order;

  switch (orderBy.toUpperCase()) {
    case 'ASC':
      order = 1;
      break;
    case 'DESC':
      order = -1;
      break;
    default:
      order = 1;
  }
  const sortedData = data.sort((a, b) => {
    const itemA = key ? a[key]?.toString() : a?.toString();
    const itemB = key ? b[key]?.toString() : b?.toString();
    let aAlpha = itemA?.replace(regexNonAlpha, '');
    let bAlpha = itemB?.replace(regexNonAlpha, '');

    if (aAlpha === bAlpha) {
      let aNum = itemA?.match(regexNumeric);
      let bNum = itemB?.match(regexNumeric);
      aNum = aNum ? parseFloat(aNum?.join('')) : 0;
      bNum = bNum ? parseFloat(bNum?.join('')) : 0;

      return aNum === bNum ? 0 : aNum < bNum ? -order : order;
    }
    return aAlpha < bAlpha ? -order : order;
  });

  return sortedData;
}

export function sortAlphaNumericForCompare(data, orderBy, key) {
  const regexNonAlpha = /[^a-zA-Z0-9]/g;
  let order;

  switch (orderBy.toUpperCase()) {
    case 'ASC':
      order = 1;
      break;
    case 'DESC':
      order = -1;
      break;
    default:
      order = 1;
  }
  const sortedData = data.sort((a, b) => {
    const itemA = key ? a[key]?.toString() : a?.toString();
    const itemB = key ? b[key]?.toString() : b?.toString();

    let aAlpha = itemA.toLowerCase();
    let bAlpha = itemB.toLowerCase();
    aAlpha = aAlpha.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    bAlpha = bAlpha.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    aAlpha = aAlpha?.replace(regexNonAlpha, '');
    bAlpha = bAlpha?.replace(regexNonAlpha, '');

    if (aAlpha === bAlpha) {
      let aNum = itemA;
      let bNum = itemB;
      aNum = aNum ? parseFloat(aNum?.join('')) : 0;
      bNum = bNum ? parseFloat(bNum?.join('')) : 0;

      return aNum === bNum ? 0 : aNum < bNum ? -order : order;
    }
    return aAlpha < bAlpha ? -order : order;
  });

  return sortedData;
}

function formatDate(groupBy) {
  let format = '';
  switch (groupBy) {
    case 'year':
      format = 'YYYY';
      break;
    case 'month':
      format = 'MM/YYYY';
      break;
    case 'day':
      format = 'DD/MM/YYYY';
      break;
    case 'hour':
      format = 'DD/MM/YYYY HH:00';
      break;
    default:
      format = 'DD/MM/YYYY HH:mm';
      break;
  }
  return format;
}

function renderLi(item) {
  const { name, color, value, magnitud } = item;
  return value.length > 0
    ? `
    <div class="esios-listEnergyItems__label ">
            <span class="esios-listEnergyItems__decorator" style="background: ${color};"></span>
            ${name}
          </div>
    <div class="value is-number">
      ${value.length > 0 ? Utils.toNumber(value[0].value, 1, 'comma') : '-'}
      <span class="unit">${MAGNITUD_SYMBOLS[magnitud[0].id]}</span>
    </div>
    `
    : '';
}

export { AnalysisSummary, AnalysisCompoSummary, hoverPie };
