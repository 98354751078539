import { html } from 'lit-html';
import { getProcessedValues } from '../../../pages/analysis/chart/analysis-comp-temp-chart';
import { fillValuesForAnalysis } from '../../../pages/gestion-demanda/gestionDemanda.service';
import { sRender } from '../../../utils/render';
import { getState } from '../../../utils/state';
import { getUrlParams } from '../../../utils/url';
import { CHART_OPTS } from './chart-default-options';
import { doValuesLogic, getDecimalAnalysisSumm } from './chart-helpers';
import { Utils } from '../../../utils/utils';

let chart = {};

const Chart = (values = [], id, opts = {}, analysis) => {
  values = formatNumbers(values);
  const { tabSelected } = analysis;
  if (tabSelected.label != 'composition') {
    values = fillValuesForAnalysis(values);
  }
  if (tabSelected.label === 'temporal') {
    values = getProcessedValues(values, analysis);
  }

  const isBreakdown =
    getState().analysis.isBreakdown && tabSelected.label === 'comparative';

  // Filtrar los valores del indicador 10463 que tengan x menor al 20 de noviembre de 2024
  const cutoffDate = new Date('2024-11-20T00:00:00+01:00');
  debugger;
  values = values.map((indicator) => {
    debugger;
    if (indicator.id === 10463) {
      debugger;
      return {
        ...indicator,
        values: indicator.values.filter(
          (item) => new Date(item.x) >= cutoffDate
        ),
      };
    }
    return indicator;
  });

  const newValues = doValuesLogic(values, isBreakdown, analysis);
  opts = { ...CHART_OPTS(newValues, opts, analysis), ...opts };
  if (chart[id]) {
    const charts = document.querySelectorAll(`#chart-${id} svg`);
    for (chart of charts) {
      chart.remove();
    }
  }

  sRender(ChartAux, `chart-${id}`);
  return html`<div class="esios-chart chari-chart" id="chart-${id}"></div>`;

  function ChartAux() {
    opts.target = d3.select(`#chart-${id}`).node();
    const chartType = opts.typeChart || 'Chart';
    document.querySelector(`#chart-${id} svg`)?.remove();
    document.querySelectorAll('.spinner-container').forEach((el) => {
      el?.classList.add('hidden');
    });
    chart[id] = new Charicharts[chartType](opts, newValues);
    window.addEventListener('resize', () => {
      document.querySelector(`#chart-${id} svg`)?.remove();
      chart[id] = new Charicharts[chartType](opts, newValues);
    });
    if (opts.chartEmitter) {
      opts.chartEmitter(chart[id]);
    }
    if (chartType === 'Pie') {
      const data = newValues.map((ind) => ({
        ...ind,
        value: ind.values,
        sign: ind.sign,
      }));
      opts.onStep && opts.onStep(data);
    }
  }
};

export default Chart;

export const formatNumbers = (arr) => {
  let values = arr.map((el) => {
    return {
      ...el,
      values: el.values.map((val) => {
        return {
          ...val,
          y: val.y?.toFixed(getDecimalAnalysisSumm(el?.magnitud[0]?.id)) * 1,
        };
      }),
    };
  });
  return values;
};
